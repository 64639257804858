import { API_URL } from "./constants";
import { parse as csvParse } from "csv-parse/dist/esm/sync.js";
import { Identifier, Record } from "react-admin";

export const urlForImage = (imagePath: string) => {
  const endpoint =
    imagePath.startsWith("users") || imagePath.startsWith("team")
      ? "/v1/assets-cached-users.jpg"
      : "/v1/assets-cached.jpg";

  const url = API_URL + endpoint + "?path=" + imagePath;

  return url;
};

/**
 * Custom error class for handling CSV processing errors
 */
export class CSVProcessError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ProcessError";
  }
}

/**
 * Parses a CSV file to extract template records.
 *
 * @param file - The CSV file to be parsed.
 * @param templateCategories - An array of template categories to map category names to IDs.
 * @returns A promise that resolves to an array of template records, each containing:
 * - `templateId`: The ID of the template.
 * - `isPro`: A boolean indicating if the template is a pro template.
 * - `categoryId`: The identifier of the category, or undefined if not found.
 *
 * @throws {CSVProcessError} If the CSV file is invalid, contains no records, or lacks the required 'Template ID' column.
 */
export function getTemplateRecordsFromCsv(
  file: File,
  templateCategories: Record[]
): Promise<
  {
    templateId: string;
    isPro: boolean;
    categoryId: Identifier | undefined;
  }[]
> {
  const reader = new FileReader();
  reader.readAsText(file);
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      const csv = reader.result as string;
      let rows;
      try {
        rows = csvParse(csv, {
          bom: true,
          columns: true,
          skip_empty_lines: true,
        });
      } catch (error) {
        reject(new CSVProcessError("Invalid CSV file."));
      }

      if (rows.length === 0) {
        reject(new CSVProcessError("No records found in the CSV file."));
      }

      // Check that the template ID column is present
      if (!rows[0].hasOwnProperty("Template ID")) {
        reject(
          new CSVProcessError("The CSV file must have a 'Template ID' column.")
        );
      }

      // Try to parse the other fields
      const records: {
        templateId: string;
        isPro: boolean;
        categoryId: Identifier | undefined;
      }[] = rows.map((row: any) => ({
        templateId: row["Template ID"].trim(),
        isPro:
          !!row["Is Pro"] &&
          ["t", "y", "1"].includes(row["Is Pro"].trim().toLowerCase()),
        categoryId: row["Category"]
          ? templateCategories.find(
              (category) => category.name === row["Category"]
            )?.id
          : undefined,
      }));

      resolve(records);
    };
  });
}

export type OfficialCategoryRecord = {
  id: Identifier;
  name: string;
  displayName: string;
  priority?: number;
  priorityConsumer?: number;
  priorityProsumer?: number;
  priorityMultiplayer?: number;
  heightRatioOverride?: number;
  draft: boolean;
  showOnHomePage: boolean;
  recommendedFor: string[];
  countryAllowList: string[];
};

/**
 * Parses a CSV file to extract official categories.
 *
 * @param file - The CSV file to be parsed.
 * @param recommendedForChoices - An array of available labels to validate the "Tags" (recommendedFor) field in the CSV file.
 * @param availableCountries - An array of available countries to validate the country allow list in the CSV file.
 * @returns A promise that resolves to an array of official category records, each containing:
 * - `id`: The ID of the category.
 * - `name`: The internal name of the category.
 * - `displayName`: The display name of the category.
 * - `priority`, `priorityConsumer`, `priorityProsumer`, `priorityMultiplayer`: The priority of the category for each priority type
 * - `heightRatioOverride`: The height ratio override of the category.
 * - `draft`: Is the category is a draft?
 * - `showOnHomePage`: Should the category be shown on the home page?
 * - `countryAllowList`: The list of countries that are allowed to access the category.
 * - `tags`: The list of tags associated with the category.
 * @throws {CSVProcessError} If the CSV file is invalid, contains no records, or lacks the required 'Template ID' column.
 */
export function getCategoriesFromCsv(
  file: File,
  recommendedForChoices: string[],
  availableCountries: string[]
): Promise<OfficialCategoryRecord[]> {
  const reader = new FileReader();
  reader.readAsText(file);
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      const csv = reader.result as string;
      let rows: object[];
      try {
        rows = csvParse(csv, {
          bom: true,
          columns: true,
          skip_empty_lines: true,
        });
      } catch (error) {
        reject(new CSVProcessError("Invalid CSV file."));
        return;
      }

      if (rows.length === 0) {
        reject(new CSVProcessError("No records found in the CSV file."));
      }

      // Check that the required columns are present
      ["ID", "Name", "Display Name"].forEach((column) => {
        if (!rows[0].hasOwnProperty(column)) {
          reject(
            new CSVProcessError(`The CSV file must have a '${column}' column.`)
          );
        }
      });

      const throwIfEmpty = <T extends string | string[]>(
        column: string,
        value: T,
        msg?: string
      ): T => {
        if (value === "" || value.length === 0) {
          if (msg) {
            throw new CSVProcessError(msg);
          } else {
            throw new CSVProcessError(
              `The '${column}' column cannot be empty.`
            );
          }
        }
        return value;
      };

      // Try to parse the other fields
      try {
        const records: OfficialCategoryRecord[] = rows.map((row: any) => ({
          id: throwIfEmpty("ID", row["ID"].trim()),
          name: throwIfEmpty("Name", row["Name"].trim()),
          displayName: throwIfEmpty("Display Name", row["Display Name"].trim()),
          priority: row["Priority"] ? parseInt(row["Priority"], 10) : 0,
          priorityConsumer: row["Priority Consumer"]
            ? parseInt(row["Priority Consumer"], 10)
            : 0,
          priorityProsumer: row["Priority Prosumer"]
            ? parseInt(row["Priority Prosumer"], 10)
            : 0,
          priorityMultiplayer: row["Priority Multiplayer"]
            ? parseInt(row["Priority Multiplayer"], 10)
            : 0,
          heightRatioOverride: row["Height Ratio Override"]
            ? parseFloat(row["Height Ratio Override"])
            : undefined,
          draft:
            !!row["Draft"] &&
            ["t", "y", "1"].includes(row["Draft"].trim().toLowerCase()),
          showOnHomePage:
            !!row["Show On Home Page"] &&
            ["t", "y", "1"].includes(
              row["Show On Home Page"].trim().toLowerCase()
            ),
          recommendedFor: row["Tags"]
            ? row["Tags"]
                .split(",")
                .map((tag: string) => tag.trim())
                .filter((tag: string) => recommendedForChoices.includes(tag))
            : [],
          countryAllowList: row["Country Allow List"]
            ? row["Country Allow List"]
                .split(",")
                .map((country: string) => country.trim().toUpperCase())
                .filter((country: string) =>
                  availableCountries.includes(country)
                )
            : [],
        }));
        resolve(records);
      } catch (error) {
        reject(error);
      }
    };
  });
}
